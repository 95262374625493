import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'

import Button from 'components/Button'

import style from './CalculatorPromo.style'

const CalculatorPromoStyled = styled.div(props => ({ ...style(props) }))

const CalculatorPromo = ({ t, title, subtitle, buttonLabel, link, denseSubtitle }) => {
    return (
        <CalculatorPromoStyled denseSubtitle={denseSubtitle}>
            <div className="content">
                <h5 className="title">{title || t('calculator-promo-title')}</h5>
                <p className="subtitle">{subtitle || t('calculator-promo-text')}</p>
                <Button variation="secondary" link={link || '/configure'}>
                    {buttonLabel || t('controls:go-to-calculator')}
                </Button>
            </div>
        </CalculatorPromoStyled>
    )
}

CalculatorPromo.propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonLabel: PropTypes.string,
    link: PropTypes.string,
    denseSubtitle: PropTypes.bool
}
CalculatorPromo.defaultProps = {
    title: null,
    subtitle: null,
    buttonLabel: null,
    link: null,
    denseSubtitle: false
}
export default withTranslation('common', 'controls')(withTheme(CalculatorPromo))
