import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'end',
    background:
        'url(https://res.cloudinary.com/merkandmerk/image/upload/v1714041063/promo-calculator-banner-mobile.png)',
    backgroundSize: 'contain',
    minHeight: 500,
    padding: toRem(30),
    borderRadius: 4,
    overflow: 'hidden',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(0, 53, 79, 0) 0%, #00354F 63%)'
    },

    [theme.breakpoints.up('md')]: {
        minHeight: 380,
        padding: toRem(65),
        alignItems: 'center',
        background:
            'url(https://res.cloudinary.com/merkandmerk/image/upload/v1714038691/promo-calculator-banner.png) no-repeat right 0 center',

        '&:before': {
            background: 'linear-gradient(90deg, #00354F 56%, rgba(0, 53, 79, 0) 100%)'
        }
    },

    // [theme.breakpoints.up('lg')]: {
    //     background:
    //         'url(https://res.cloudinary.com/merkandmerk/image/upload/v1605776264/Static/calculator-bg-3_jz1g2k.jpg) no-repeat center center'
    // },

    '& .content': {
        position: 'relative',
        // display: 'flex',
        // flexDirection: 'column',
        // alignSelf: 'end'
    },

    '& .title': {
        marginBottom: toRem(10),
        maxWidth: toRem(580),
        fontSize: toRem(36),
        color: theme.colors.goldLight,
        fontWeight: 500,
        lineHeight: 1.2,

        [theme.breakpoints.up('md')]: {
            fontSize: toRem(45),
            fontWeight: 500
        }
    },

    '& .subtitle': {
        maxWidth: toRem(580),
        fontWeight: 500,
        marginBottom: toRem(20),

        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(30)
        }
    }
})

export default style
