import Head from 'next/head'
import { useMemo } from 'react'
import { withTranslation } from 'server/i18n'
import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import { Flex, Box } from 'reflexbox'
import getConfig from 'next/config'

import { getImageURL, getBoatLink } from 'helpers/middleware'
import PromoBlock from 'components/PromoBlock'
import Map from 'components/Map'
import OurPartners from 'components/OurPartners'
import Testimonials from 'components/Testimonials'
import InquiryForm from 'components/InquiryForm'
import BlockTitle from 'components/BlockTitle'
import NewsTeaser from 'components/NewsTeaser'
import HomepageHero from 'components//HomepageHero'
import layoutTheme from 'theme/layout-theme'
import TextBlock from 'components/TextBlock'
import BuyManageCta from 'components/BuyManageCTA'
import PhotoGallerySlider from 'components/PhotoGallerySlider'
import { useBoatList } from 'helpers/hooks'
import NewsletterPromo from 'components/NewsletterPromo'
import CalculatorPromo from 'components/CalculatorPromo'
import { getTestimonials, getPartners, getPosts } from 'helpers/wordpress/wp-api'

const { publicRuntimeConfig } = getConfig()
const { CLOUDINARY_URL, DOMAIN_ROOT } = publicRuntimeConfig

const Index = ({ canonical, t, i18n, testimonials, partners, news }) => {
    // Fetch boats listing data
    const { data: boatsListingData } = useBoatList()

    // Filter boats listing data for slider
    const featuredYachts = useMemo(() => {
        if (!boatsListingData || !boatsListingData.length) {
            return []
        }

        const featuredList = boatsListingData.filter(boat => boat.published && boat.featured)

        if (featuredList.length < 3) {
            return []
        }

        // eslint-disable-next-line no-underscore-dangle
        featuredList.sort((boatA, boatB) => boatB?.created_at?._seconds - boatA?.created_at?._seconds)

        return featuredList.map(({ attachments, info, slug, price }) => ({
            url: getImageURL((attachments || {}).images, { position: 'listing', size: 'featured', hasFallback: true }),
            name: info.model,
            condition: t(`controls:${info.condition}`),
            year: info.year,
            length: info.length,
            price: price.displayValue,
            vat: price.vat,
            boatURL: getBoatLink(info.type, info.model, slug, i18n.language).href,
            boatURLas: getBoatLink(info.type, info.model, slug, i18n.language).as,
            currency: price.currency
        }))
    }, [t, boatsListingData])

    const seoData = {
        title: `${t('meta-data:home-title')} | Merk & Merk`,
        description: t('meta-data:home-description'),
        canonical: i18n.language === 'en' ? `${DOMAIN_ROOT}` : `${DOMAIN_ROOT}/de`,
        openGraph: {
            title: `${t('meta-data:home-title')} | Merk & Merk`,
            description: t('meta-data:home-description'),
            url: t('meta-data:home-url'),
            images: [
                {
                    url: `${CLOUDINARY_URL}/v1582626095/Static/buy-alt-2_f7md4v.jpg`
                }
            ]
        },
        languageAlternates: [
            {
                hrefLang: 'en',
                href: `${DOMAIN_ROOT}`
            },
            {
                hrefLang: 'de',
                href: `${DOMAIN_ROOT}/de`
            }
        ]
    }

    return (
        <div>
            <NextSeo {...seoData} />
            <Head>
                <script
                    language="javascript"
                    type="text/javascript"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                        __html: `(function (id, f, t, ws, ms_tr_il_08, ms_tr_il_w_01) { var tr = function (cb) { var count = 0; var callback = function () { if (count == 0) { count++; if (w) { w.w(id, t, cb); } } }; var ts = document.createElement('script'); ts.src = ws; ts.type = 'text/javascript'; ts.onload = callback; ts.onreadystatechange = function () { if (this.readyState == 'complete' || this.readyState == 'loaded') { callback(); } }; var head = document.getElementsByTagName('head')[0]; head.appendChild(ts); }; if (typeof ms_tr_il_08 === 'function') { if (ms_tr_il_w_01 === null) { tr(function() { ms_tr_il_08(id, f, t); }); } else { ms_tr_il_w_01.w(id, t, function(websiteVisitedParams) { ms_tr_il_08(id, f, t, websiteVisitedParams); }, null); } } else { tr(); }})('QVRMMH_hwEpAt5HmYloE8xJDOYijKftPQGakg8NYjQQ', 'https://a78037f57059413b89ee955ac3aa54f4.svc.dynamics.com/f', 'https://a78037f57059413b89ee955ac3aa54f4.svc.dynamics.com/t', 'https://a78037f57059413b89ee955ac3aa54f4.svc.dynamics.com/t/w', typeof ms_tr_il_08 === "undefined" ? null : ms_tr_il_08, typeof ms_tr_il_w_01 === "undefined" ? null : ms_tr_il_w_01);`
                    }}
                />
            </Head>

            <Box theme={layoutTheme}>
                <HomepageHero />
            </Box>

            <Box theme={layoutTheme} px={20} mt={[null, null, null, 60]} mb={[40, null, null, 60]} variant="center1600">
                <PromoBlock
                    isHomepage
                    image={`${CLOUDINARY_URL}/v1582626478/Static/sailing-into-the-sunset_nup13s.jpg`}
                    title={t('promo-title-1')}
                    subtitle={t('promo-lead-1')}
                    text={t('promo-content-1')}
                    buttonText={t('about-us')}
                    buttonLink="/about"
                />
            </Box>

            <Box theme={layoutTheme} px={20} mt={[40, null, null, 60]} mb={[80, null, null, 110]} variant="center1600">
                <BuyManageCta />
            </Box>

            {featuredYachts.length ? (
                <Box
                    theme={layoutTheme}
                    bg="#003D5A"
                    pt={[40, null, null, null, 80]}
                    pb={[40, null, null, null, 0]}
                    mb={80}>
                    <Box
                        theme={layoutTheme}
                        fontSize={[28, null, null, null, 40]}
                        fontWeight={500}
                        pl={[20, null, null, 60, null, 160]}>
                        {t('featured-yachts')}
                    </Box>
                    <Box
                        theme={layoutTheme}
                        fontSize={[20, null, null, null, 24]}
                        color="#E3B05F"
                        mb={30}
                        pl={[20, null, null, 60, null, 160]}>
                        {t('fresh-waiting-for-you')}
                    </Box>

                    <Box theme={layoutTheme} pl={[20, null, null, 40, null, 0]}>
                        <PhotoGallerySlider images={featuredYachts} specialOffers featuredButton />
                    </Box>
                </Box>
            ) : null}

            <Box theme={layoutTheme} px={20} mt={[80, null, null, 120]} mb={[60, null, null, 120]} variant="center1600">
                <CalculatorPromo />
            </Box>

            <Box theme={layoutTheme} variant="blockTitle">
                <Box theme={layoutTheme} fontSize={[35, null, null, null, 45]} fontWeight={600}>
                    {t('yacht-news')}
                </Box>
                <Box theme={layoutTheme} fontSize={[20, null, null, null, 24]} color="#E3B05F" mb={[null, 30]}>
                    {t('news-subtitle')}
                </Box>
            </Box>

            <Box theme={layoutTheme}>
                <NewsTeaser posts={news} />
            </Box>

            <Box theme={layoutTheme} variant="blockTitle">
                <Box theme={layoutTheme} fontSize={[35, null, null, null, 45]} fontWeight={600}>
                    {t('our-partners')}
                </Box>
                <Box theme={layoutTheme} fontSize={[20, null, null, null, 24]} color="#E3B05F" mb={30}>
                    {t('our-partners-subtitle')}
                </Box>
            </Box>

            <OurPartners partners={partners} />

            <Box
                theme={layoutTheme}
                variant="center1360"
                px={[20, null, null, null, null]}
                mt={[0, 0, 80, 140]}
                mb={[80, null, 80, 140]}>
                <Testimonials
                    testimonials={testimonials}
                    title={t('what-they-say')}
                    subtitle={t('hopefully-good-stuff')}
                />
            </Box>

            <Box theme={layoutTheme} mb={[0, 0, 0, 60]} variant="blockTitle">
                <BlockTitle title={t('contact-for-evaluation')} subtitle={t('fill-it-out')} paddingLeft={0} />
            </Box>

            <Box theme={layoutTheme} px={20} maxWidth={1360} mx="auto">
                <InquiryForm onPage />
            </Box>

            <Box theme={layoutTheme} px={20} mt={[80, null, null, 140]} mb={[80, null, null, 140]} variant="center1600">
                <NewsletterPromo />
            </Box>

            <Flex theme={layoutTheme} variant="mapTitle">
                <Box theme={layoutTheme}>
                    <BlockTitle title={t('our-location')} subtitle={t('our-location-text')} paddingLeft={0} />
                </Box>

                <Box theme={layoutTheme} variant="mapAddress">
                    {/* eslint-disable-next-line max-len */}
                    <TextBlock text="<address>Jurjevgradska 2, Marina Tribunj<br> HR-22212 Tribunj, Croatia</address>" />
                </Box>
            </Flex>

            <Box theme={layoutTheme} mb={[20, null, 80, 190]} variant="center1600">
                <Map />
            </Box>

            <Box theme={layoutTheme} mb={100} px="40px" mx="auto" display={['block', null, 'none']}>
                <TextBlock text="<address>Jurjevgradska 2, Marina Tribunj<br> HR-22212 Tribunj, Croatia</address>" />
            </Box>
        </div>
    )
}

Index.getInitialProps = async ({ req: { i18n } = {} }) => {
    const testimonials = await getTestimonials()
    const partners = await getPartners()
    const data = await getPosts(i18n?.language?.toUpperCase() || 'EN', '', '')
    const news = data?.posts?.nodes

    return {
        canonical: i18n ? i18n.getFixedT('en')('meta-data:home-url') : undefined,
        namespacesRequired: ['common', 'controls', 'testimonials', 'meta-data'],
        testimonials: testimonials.testimonials.nodes,
        partners: partners.partners.nodes,
        news
    }
}

Index.propTypes = {
    canonical: PropTypes.string,
    i18n: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired,
    t: PropTypes.func.isRequired,
    testimonials: PropTypes.arrayOf(),
    partners: PropTypes.arrayOf(),
    news: PropTypes.arrayOf().isRequired
}

Index.defaultProps = {
    canonical: undefined,
    testimonials: null,
    partners: null
}

export default withTranslation('common', 'controls', 'testimonials', 'meta-data')(Index)
